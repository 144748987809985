import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { storage } from "../firebaseConfig"; // Import Firebase storage
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage methods
import useMediaQuery from "../useMediaQuery";
import { useAuth } from '../AuthProvider';

// Container for the whole page
const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background: url('https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_20.png?v=1725961182') no-repeat center top fixed; /* Sticky background */
  background-size: cover;
 
`;

const FormContainer = styled.div`
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Glassy effect */
  padding: 20px;
  width: 90%; /* Set width to 90% for better responsiveness */
  max-width: 600px; /* Maximum width for form */
  margin: 0px auto; /* Center the form */
  height: auto; /* Allow the form to grow based on content */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  border-radius:20px;

  /* Remove max-height and overflow-y */
  overflow-y: visible; /* Ensure overflow is visible */
  max-height: none; /* Allow form to grow indefinitely */

  /* Responsive styles */
  @media (max-width: 768px) {
    padding: 15px; /* Reduce padding on smaller screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Further reduce padding on mobile */
  }
`;



const FormGrid = styled.div`
  display: flex;
  flex-direction: column; /* Ensure single column layout */
  gap: 20px; /* Space between form groups */
  margin-top: 50px; /* Additional gap above form items */
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const FullWidthFormGroup = styled(FormGroup)`
  grid-column: span 1; /* Single column, so span only 1 column */
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  font-family: 'lora';
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f2f8fc;
  border-radius: 5px;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 14px; /* Reduce font size on mobile */
  }
`;

const Dropdown = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f2f8fc;
  border-radius: 5px;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 14px; /* Reduce font size on mobile */
  }
`;

const FileUpload = styled.input`
  display: block;
  margin-top: 10px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom:20px;


  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

const SuccessMessage = styled.div`
  position: fixed; /* Use fixed to keep it centered in the viewport */
  top: 90%; /* Center vertically in the viewport */
  left: 50%; /* Center horizontally in the viewport */
  transform: translate(-50%, -50%); /* Adjust position to center */
  background-color: rgba(230, 230, 230, 0.8); /* Adjusted transparency for better visibility */
  color: green;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out, visibility 0.2s;
  z-index: 1000;
  width: 250px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;



const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const Title = styled.h1`
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Space below the title */
  font-size: 24px; /* Default font size */
  font-family: 'lora';

  @media (max-width: 768px) {
    font-size: 20px; /* Smaller font size for tablets */
    font-family: 'lora';
  }

  @media (max-width: 480px) {
    font-size: 18px; /* Smaller font size for mobile */
    font-family: 'lora';
  }
`;


const Sell = () => {
  const [sizeUnit, setSizeUnit] = useState('');
  const [formData, setFormData] = useState({
    files: [],
    imageUrls: [],
    propertyName: "",
    propertyType: "",
    length: "",
    breadth: "",
    numberOfSqFeet: "",
    // buildUpArea: "",
    buildUpAreaSqFt: '',
    numberOfCent: '',
    buildUpAreaCent: '',
    numberOfAcre: '',
    buildUpAreaAcre: '',
    electricity: '',
    waterFacility: '',
    boreWell: '',
    roadFacility: '',
    ageOfProperty: "",
    budget: "",
    numberOfBathrooms: "",
    numberOfRooms: "",
    parking: "",
    lift: "",
    types: "",
    contactOwnerName: "",
    contactOwnerPhone: "",
    propertyAddress: "",
    city: "",
    zipCode: "",
    state: "",
    createdBy: "",
    amenities: "",
    budgetMethod: "",
    userType: "",
    Noofyears: "",
    facingdirection: "",
  });


  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [propertyName, setPropertyName] = useState("");
  const { userDetails } = useAuth();

  // Fetch user details from local storage or context
  useEffect(() => {
    if (userDetails && userDetails.email) {
      setFormData((prevData) => ({
        ...prevData,
        createdBy: userDetails.email,
      }));
    } else {
      try {
        const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (storedUserDetails && storedUserDetails.email) {
          setFormData((prevData) => ({
            ...prevData,
            createdBy: storedUserDetails.email,
          }));
        }
      } catch (error) {
        console.error('Error parsing user details:', error);
      }
    }
  }, [userDetails]);

  const handleToggle = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: prevData[name] === 'yes' ? 'no' : 'yes',
    }));
  };

  const validateForm = (data) => {
    const errors = {};

    // Check for property name and property type (common for all properties)
    if (!data.propertyName) errors.propertyName = "Property Name is required.";
    if (!data.propertyType) errors.propertyType = "Property Type is required.";
    if (!data.userType) errors.userType = "user Type is required.";
    // If property is not 'Land', validate these fields
    // if (data.propertyName !== 'Land') {
    // if (!data.numberOfBathrooms) errors.numberOfBathrooms = "Number of Bathrooms is required.";
    // if (!data.numberOfRooms) errors.numberOfRooms = "Number of Rooms is required.";
    // if (!data.parking) errors.parking = "Parking information is required.";
    // if (!data.lift) errors.lift = "Lift information is required.";

    // Validate size units for non-land properties
    //   if (sizeUnit === "Square ft") {
    //     if (!data.numberOfSqFeet) errors.numberOfSqFeet = "Number of Square Feet is required.";
    //     // if (!data.buildUpAreaSqFt) errors.buildUpAreaSqFt = "Build Up Area (Sq Ft) is required.";
    //   } else if (sizeUnit === "Cent") {
    //     if (!data.numberOfCent) errors.numberOfCent = "Number of Cent is required.";
    //     // if (!data.buildUpAreaCent) errors.buildUpAreaCent = "Build Up Area (Cent) is required.";
    //   } else if (sizeUnit === "Acre") {
    //     if (!data.numberOfAcre) errors.numberOfAcre = "Number of Acre is required.";
    //     // if (!data.buildUpAreaAcre) errors.buildUpAreaAcre = "Build Up Area (Acre) is required.";
    //   }
    // } else {
    //   // If property is 'Land', validate only land-specific fields
    //   if (sizeUnit === "Square ft" && !data.numberOfSqFeet) {
    //     errors.numberOfSqFeet = "Number of Square Feet is required for land.";
    //   } else if (sizeUnit === "Cent" && !data.numberOfCent) {
    //     errors.numberOfCent = "Number of Cent is required for land.";
    //   } else if (sizeUnit === "Acre" && !data.numberOfAcre) {
    //     errors.numberOfAcre = "Number of Acre is required for land.";
    //   }
    // }

    // Validate other required fields
    // if (!data.electricity) errors.electricity = "Electricity status is required.";
    // if (!data.waterFacility) errors.waterFacility = "Water Facility status is required.";
    // if (!data.boreWell) errors.boreWell = "Bore-Well status is required.";
    // if (!data.roadFacility) errors.roadFacility = "Road Facility status is required.";
    // if (!data.ageOfProperty) errors.ageOfProperty = "Age of Property is required.";
    if (!data.budget) errors.budget = "Budget is required.";
    if (!data.types) errors.types = "Types are required.";
    if (!data.contactOwnerName) errors.contactOwnerName = "Contact Owner Name is required.";
    if (!data.contactOwnerPhone) errors.contactOwnerPhone = "Contact Owner Phone is required.";
    if (!data.propertyAddress) errors.propertyAddress = "Property Address is required.";
    if (!data.city) errors.city = "City is required.";
    if (!data.state) errors.state = "State is required.";
    if (!data.zipCode) errors.zipCode = "PIN Code is required.";

    return errors;
  };

  // Function to toggle fields based on the selected size unit
  const toggleFields = (e) => {
    const newUnit = e.target.value;

    // Update sizeUnit state
    setSizeUnit(newUnit);

    // Convert values based on the selected unit
    if (newUnit === 'Square ft' && formData.buildUpAreaCent) {
      // Convert Cent to Square Feet
      const buildUpAreaSqFt = (parseFloat(formData.buildUpAreaCent) * 435.6).toFixed(2);
      setFormData({
        ...formData,
        sizeUnit: newUnit,
        buildUpAreaSqFt,
      });
    } else if (newUnit === 'Cent' && formData.buildUpAreaSqFt) {
      // Convert Square Feet to Cent
      const buildUpAreaCent = (parseFloat(formData.buildUpAreaSqFt) / 435.6).toFixed(2);
      setFormData({
        ...formData,
        sizeUnit: newUnit,
        buildUpAreaCent,
      });
    } else {
      // Just update the size unit if no values exist yet
      setFormData({
        ...formData,
        sizeUnit: newUnit,
      });
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input is for the budget, remove commas before updating state
    const sanitizedValue = name === 'budget' ? value.replace(/,/g, '') : value;

    // Update the form data with the new input value (with commas removed for budget)
    setFormData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));

    // Clear the specific error for the field being updated
    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: _, ...rest } = prevErrors;
        return rest;
      });
    }

    // Additional logic for length, breadth, and buildUpArea handling
    if (name === 'length' || name === 'breadth') {
      const length = parseFloat(name === 'length' ? sanitizedValue : formData.length) || 0;
      const breadth = parseFloat(name === 'breadth' ? sanitizedValue : formData.breadth) || 0;
      const areaSqFeet = length * breadth;
      const numberOfCent = areaSqFeet / 435.6;
      const numberOfAcre = areaSqFeet / 43560;

      setFormData((prevData) => ({
        ...prevData,
        numberOfSqFeet: areaSqFeet.toFixed(2),
        numberOfCent: numberOfCent.toFixed(2),
        numberOfAcre: numberOfAcre.toFixed(2),
      }));
    }

    // Logic for calculating build up area
    if (name === 'buildUpAreaSqFt') {
      const buildUpAreaSqFt = parseFloat(sanitizedValue) || 0;
      const buildUpAreaCent = (buildUpAreaSqFt / 435.6).toFixed(2);
      setFormData((prevData) => ({
        ...prevData,
        buildUpAreaCent,
        buildUpAreaSqFt: sanitizedValue,
      }));
    } else if (name === 'buildUpAreaCent') {
      const buildUpAreaCent = parseFloat(sanitizedValue) || 0;
      const buildUpAreaSqFt = (buildUpAreaCent * 435.6).toFixed(2);
      setFormData((prevData) => ({
        ...prevData,
        buildUpAreaSqFt,
        buildUpAreaCent: sanitizedValue,
      }));
    } else if (name === 'buildUpAreaAcre') {
      const buildUpAreaAcre = parseFloat(sanitizedValue) || 0;
      const buildUpAreaSqFt = (buildUpAreaAcre * 43560).toFixed(2);
      const buildUpAreaCent = (buildUpAreaSqFt / 435.6).toFixed(2);
      setFormData((prevData) => ({
        ...prevData,
        buildUpAreaSqFt,
        buildUpAreaCent,
        buildUpAreaAcre: sanitizedValue,
      }));
    }
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files); // New files
    const maxFiles = 15; // Maximum number of files allowed
  
    // Combine existing and newly selected files
    const updatedFiles = [...(formData.imageUrls || []), ...selectedFiles];
  
    if (updatedFiles.length > maxFiles) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: `You can upload a maximum of ${maxFiles} files.`,
      }));
      return; // Prevent further processing
    }
  
    try {
      const newImageUrls = await Promise.all(
        selectedFiles.map(async (file) => {
          const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
          await uploadBytes(storageRef, file);
          const downloadURL = await getDownloadURL(storageRef);
          return downloadURL; // Returning the URL of the uploaded file
        })
      );
  
      // Update formData with the new file URLs
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageUrls: [...(prevFormData.imageUrls || []), ...newImageUrls],
      }));
  
      // Clear any existing file errors
      setErrors((prevErrors) => {
        const { files, ...rest } = prevErrors;
        return rest;
      });
    } catch (error) {
      console.error("Error uploading files:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: "Failed to upload files.",
      }));
    }
  };
  
  const addWatermark = (file, watermarkText) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the original image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Set watermark style
        ctx.font = "bold 150px Arial";
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        ctx.textAlign = "center";

        // Position watermark in the center of the image
        const x = canvas.width / 2;
        const y = canvas.height / 2;
        ctx.fillText(watermarkText, x, y);

        // Convert canvas to Blob for upload
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/jpeg", 0.95); // Adjust quality if needed
      };
      img.onerror = (error) => reject(error);
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) return; // Stop submission if there are errors

    try {
      // Submit the form along with image URLs
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/Sell`, formData);
      console.log(response.data);
      alert("Form submitted successfully!");
      // Show success message and reset form after 5 seconds
      setSuccessMessageVisible(true);
      setTimeout(() => {
        setSuccessMessageVisible(false);
        setFormData({
          files: [],
          imageUrls: [], // Reset the image URLs
          propertyName: "",
          propertyType: "",
          numberOfSqFeet: "",
          buildUpAreaSqFt: "",
          numberOfCent: "",
          buildUpAreaCent: "",
          numberOfAcre: "",
          buildUpAreaAcre: "",
          ageOfProperty: "",
          budget: "",
          numberOfBathrooms: "",
          numberOfRooms: "",
          parking: "",
          lift: "",
          types: "",
          contactOwnerName: "",
          contactOwnerPhone: "",
          propertyAddress: "",
          city: "",
          boreWell: "",
          waterFacility: "",
          zipCode: "",
          state: "",
          createdBy: userDetails ? userDetails.email : "",
          amenities: "",
          budgetMethod: "",
          userType: '',
          Noofyears: "",
          facingdirection: "",
        });
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitErrorMessage("Failed to submit the form. Please try again.");
    }
  };

  // Handler for numberOfSqFeet, numberOfCent, and numberOfAcre
  const handleSizeUnitChange = (e) => {
    const { name, value } = e.target;
    const sizeValue = parseFloat(value) || 0;

    if (name === "numberOfSqFeet") {
      // Convert Sq Ft to Cent and Acre
      const numberOfCent = (sizeValue / 435.6).toFixed(2);
      const numberOfAcre = (sizeValue / 43560).toFixed(4);

      setFormData({
        ...formData,
        numberOfSqFeet: value,
        numberOfCent, // Auto-calculated
        numberOfAcre, // Auto-calculated
      });
    } else if (name === "numberOfCent") {
      // Convert Cent to Sq Ft and Acre
      const numberOfSqFeet = (sizeValue * 435.6).toFixed(2);
      const numberOfAcre = (numberOfSqFeet / 43560).toFixed(4);

      setFormData({
        ...formData,
        numberOfCent: value,
        numberOfSqFeet, // Auto-calculated
        numberOfAcre, // Auto-calculated
      });
    } else if (name === "numberOfAcre") {
      // Convert Acre to Sq Ft and Cent
      const numberOfSqFeet = (sizeValue * 43560).toFixed(2);
      const numberOfCent = (numberOfSqFeet / 435.6).toFixed(2);

      setFormData({
        ...formData,
        numberOfAcre: value,
        numberOfSqFeet, // Auto-calculated
        numberOfCent, // Auto-calculated
      });
    }
  };

  const handleBuildUpAreaChange = (e) => {
    const { name, value } = e.target;
    const buildUpArea = parseInt(value) || 0;
    // Validate against numberOfSqFeet
    if (name === 'buildUpAreaSqFt' && buildUpArea > parseInt(formData.numberOfSqFeet)) {
      alert("Build Up Area cannot be greater than Number of Sq Feet.");
    } else {
      // Calculate Cent equivalent and set both values
      const buildUpAreaCent = (buildUpArea / 435.6).toFixed(2);
      const buildUpAreaAcre = (buildUpAreaSqFt / 43560).toFixed(4);
      setFormData({
        ...formData,
        buildUpAreaSqFt: value,
        buildUpAreaCent, // Auto-calculated
        buildUpAreaAcre, // Auto-calculated
      });
    }
  };

  const handleBuildUpAreaChange1 = (e) => {
    const { name, value } = e.target;
    const buildUpArea = parseInt(value) || 0;

    // Validate against numberOfCent
    if (name === 'buildUpAreaCent' && buildUpArea > parseInt(formData.numberOfCent)) {
      alert("Build Up Area cannot be greater than Number of Cent.");
    } else {
      // Calculate Sq Ft equivalent and set both values
      const buildUpAreaSqFt = (buildUpArea * 435.6).toFixed(2);
      const buildUpAreaAcre = (buildUpAreaSqFt / 43560).toFixed(4);
      setFormData({
        ...formData,
        buildUpAreaCent: value,
        buildUpAreaSqFt, // Auto-calculated
        buildUpAreaAcre,
      });
    }
  };

  const handleBuildUpAreaChange2 = (e) => {
    const { name, value } = e.target;
    const buildUpArea = parseInt(value) || 0;

    if (name === 'buildUpAreaAcre' && buildUpArea > parseInt(formData.numberOfAcre)) {
      alert("Build Up Area cannot be greater than Number of Acre.");
    } else {
      // Calculate Sq Ft and Cent equivalents
      const buildUpAreaSqFt = (buildUpArea * 43560).toFixed(2); // Convert acre to square feet
      const buildUpAreaCent = (buildUpAreaSqFt / 435.6).toFixed(2); // Convert square feet to cent
      setFormData({
        ...formData,
        buildUpAreaAcre: value,
        buildUpAreaSqFt, // Auto-calculated
        buildUpAreaCent, // Auto-calculated
      });
    }
  };

  const isMobile = useMediaQuery("(max-width: 480px)");
  const isSmallTablet = useMediaQuery(
    "(min-width: 481px) and (max-width: 530px)"
  );
  const isTablet = useMediaQuery("(min-width: 531px) and (max-width: 768px)");

  let columns;
  if (isMobile) {
    columns = 1;
  } else if (isSmallTablet) {
    columns = 1;
  } else if (isTablet) {
    columns = 2;
  } else {
    columns = 2;
  }

  // window.scrollTo({top:0, behavior: 'smooth'})

  const removeImage = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageUrls: prevFormData.imageUrls.filter((_, i) => i !== index), // Filter out the image at the given index
    }));
  };

  return (
    <PageContainer>

      <FormContainer>
        <Title>Sell Your Property</Title>
        <form onSubmit={handleSubmit}>
          <FormGrid >
            <FormGroup>
              <Label htmlFor="propertyName">Property Name <span style={{ color: "red" }}>*</span></Label>
              <Dropdown
                name="propertyName"
                value={formData.propertyName}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="House">House</option>
                <option value="Land">Land</option>
                <option value="Apartment">Apartment</option>
                <option value="Store">Store</option>
                <option value="Farm House">Farm House</option>
              </Dropdown>
              {errors.propertyName && (
                <ErrorMessage>{errors.propertyName}</ErrorMessage>
              )}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="types">Types <span style={{ color: "red" }}>*</span></Label>
              <Dropdown
                name="types"
                value={formData.types}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Rent">Rent</option>
                <option value="Lease">Lease</option>
                <option value="Sell">Sell</option>
              </Dropdown>
              {errors.types && <ErrorMessage>{errors.types}</ErrorMessage>}
            </FormGroup>

            {formData.types === 'Lease' && (
              <>

                <FormGroup>
                  <Label htmlFor="Noofyears">No.of Years:</Label>
                  <Input
                    type="number"
                    id="Noofyears"
                    name="Noofyears"
                    value={formData.Noofyears}
                    onChange={handleInputChange}
                  />
                  {errors.Noofyears && <ErrorMessage>{errors.Noofyears}</ErrorMessage>}
                </FormGroup>
              </>
            )}

            <FormGroup>
              <Label htmlFor="propertyType">Property Type <span style={{ color: "red" }}>*</span></Label>
              <Dropdown
                name="propertyType"
                value={formData.propertyType}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Commercial">Commercial</option>
                <option value="Residential">Residential</option>
              </Dropdown>
              {errors.propertyType && (
                <ErrorMessage>{errors.propertyType}</ErrorMessage>
              )}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="userType">User Type: <span style={{ color: "red" }}>*</span></Label>
              <Dropdown
                name="userType"
                value={formData.userType}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Broker">Broker</option>
                <option value="Owner">Owner</option>
              </Dropdown>
            </FormGroup>

            <FullWidthFormGroup columns={columns}>
              <Label htmlFor="files">
                Upload Files (Maximum 15 Files "Photos and Videos" only Upload) <span style={{ color: "red" }}>*</span>
              </Label>
              <FileUpload
                type="file"
                name="files"
                id="files" // Ensure this ID matches the label's htmlFor for accessibility
                multiple
                onChange={handleFileChange}
              />
              {errors.files && <ErrorMessage>{errors.files}</ErrorMessage>}

              {/* Display selected files */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {formData.imageUrls && formData.imageUrls.map((url, index) => {
                  const isVideo = url.toLowerCase().includes('.mp4'); // Check if the file is a video
                  return (
                    <div key={index} style={{ position: 'relative', width: '150px' }}>
                      {isVideo ? (
                        <video width="150" controls>
                          <source src={url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img src={url} alt={`Uploaded file ${index}`} style={{ width: '150px' }} />
                      )}

                      {/* Close icon to remove the image */}
                      <span
                        onClick={() => removeImage(index)}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          cursor: 'pointer',
                          color: 'red',
                          fontSize: '16px',
                          background: 'white',
                          borderRadius: '50%',
                          padding: '0 5px',
                          margin: '2px',
                          zIndex: 1,
                        }}
                      >
                        &times; {/* You can replace this with an icon from an icon library */}
                      </span>
                    </div>
                  );
                })}
              </div>
            </FullWidthFormGroup>
            <FormGroup>
              <Label htmlFor="sizeUnit">Choose Size Unit <span style={{ color: "red" }}>*</span> </Label>
              <Dropdown
                name="sizeUnit"
                value={sizeUnit}
                onChange={toggleFields} // Handles unit switch
              >
                <option value="">Select</option>
                <option value="Square ft">Square ft</option>
                <option value="Cent">Cent</option>
                <option value="Acre">Acre</option>
              </Dropdown>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="budgetMethod">Choose Budget Method <span style={{ color: "red" }}>*</span> </Label>
              <Dropdown
                name="budgetMethod"
                value={formData.budgetMethod}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Overall budget">Overall budget</option>
                <option value="Per Cent">Per Cent</option>
                <option value="Per Acre">Per Acre</option>
                <option value="Monthly">Monthly</option>
                {/* <option value="No.of Years">No.of Years</option> */}

              </Dropdown>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="budget">Budget <span style={{ color: "red" }}>*</span></Label>
              <Input
                type="text"
                name="budget"
                value={formData.budget}
                onChange={handleInputChange}
              />
              {errors.budget && <ErrorMessage>{errors.budget}</ErrorMessage>}
            </FormGroup>

           

            {/* shan */}
            <FormGroup>
              <Label htmlFor="length">Length(in Feet):</Label>
              <Input
                type="number"
                id="length"
                name="length"
                value={formData.length}
                onChange={handleInputChange}
              />
              {errors.length && <ErrorMessage>{errors.length}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="breadth">Breadth(in Feet):</Label>
              <Input
                type="number"
                id="breadth"
                name="breadth"
                value={formData.breadth}
                onChange={handleInputChange}
              />
              {errors.breadth && <ErrorMessage>{errors.breadth}</ErrorMessage>}
            </FormGroup>

            {/* Conditional Number of Sq Feet or Cent fields */}
            {/* Always display "numberOfSqFeet", "numberOfCent", and "numberOfAcre" based on sizeUnit */}
            {sizeUnit === 'Square ft' && (
              <FormGroup>
                <Label htmlFor="numberOfSqFeet">Number of Sq Feet: <span style={{ color: "red" }}>*</span></Label>
                <Input
                  type="number"
                  id="numberOfSqFeet"
                  name="numberOfSqFeet"
                  value={formData.numberOfSqFeet}
                  onChange={handleSizeUnitChange}
                />
                {errors.numberOfSqFeet && <ErrorMessage>{errors.numberOfSqFeet}</ErrorMessage>}
              </FormGroup>
            )}

            {sizeUnit === 'Cent' && (
              <FormGroup>
                <Label htmlFor="numberOfCent">Number of Cent: <span style={{ color: "red" }}>*</span></Label>
                <Input
                  type="number"
                  id="numberOfCent"
                  name="numberOfCent"
                  value={formData.numberOfCent}
                  onChange={handleSizeUnitChange}
                />
                {errors.numberOfCent && <ErrorMessage>{errors.numberOfCent}</ErrorMessage>}
              </FormGroup>
            )}

            {sizeUnit === 'Acre' && (
              <FormGroup>
                <Label htmlFor="numberOfAcre">Number of Acres:</Label>
                <Input
                  type="number"
                  id="numberOfAcre"
                  name="numberOfAcre"
                  value={formData.numberOfAcre}
                  onChange={handleSizeUnitChange}
                />
                {errors.numberOfAcre && <ErrorMessage>{errors.numberOfAcre}</ErrorMessage>}
              </FormGroup>
            )}

            {/* Conditionally hide "Build Up Area" fields if propertyName is "Land" */}
            {formData.propertyName !== 'Land' && (
              <>
                {sizeUnit === 'Square ft' && (
                  <FormGroup>
                    <Label htmlFor="buildUpAreaSqFt">Build Up Area in Square ft:</Label>
                    <Input
                      type="number"
                      id="buildUpAreaSqFt"
                      name="buildUpAreaSqFt"
                      value={formData.buildUpAreaSqFt}
                      onChange={handleBuildUpAreaChange}
                    />
                    {errors.buildUpAreaSqFt && <ErrorMessage>{errors.buildUpAreaSqFt}</ErrorMessage>}
                  </FormGroup>
                )}

                {sizeUnit === 'Cent' && (
                  <FormGroup>
                    <Label htmlFor="buildUpAreaCent">Build Up Area in Cent:</Label>
                    <Input
                      type="number"
                      id="buildUpAreaCent"
                      name="buildUpAreaCent"
                      value={formData.buildUpAreaCent}
                      onChange={handleBuildUpAreaChange1}
                    />
                    {errors.buildUpAreaCent && <ErrorMessage>{errors.buildUpAreaCent}</ErrorMessage>}
                  </FormGroup>
                )}

                {sizeUnit === 'Acre' && (
                  <FormGroup>
                    <Label htmlFor="buildUpAreaAcre">Build Up Area in Acres:</Label>
                    <Input
                      type="number"
                      id="buildUpAreaAcre"
                      name="buildUpAreaAcre"
                      value={formData.buildUpAreaAcre}
                      onChange={handleBuildUpAreaChange2}
                    />
                    {errors.buildUpAreaAcre && <ErrorMessage>{errors.buildUpAreaAcre}</ErrorMessage>}
                  </FormGroup>
                )}
              </>
            )}
            {/* Yes/No Radio buttons for other fields */}
            <FormGroup>
              <Label htmlFor="electricity">Electricity:</Label>
              <Dropdown
                name="electricity"
                value={formData.electricity}
                onChange={handleInputChange}
              >
                <option value="">Select the options</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Dropdown>
              {errors.electricity && <ErrorMessage>{errors.electricity}</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="waterFacility">Water Facility:</Label>
              <Dropdown
                name="waterFacility"
                value={formData.waterFacility}
                onChange={handleInputChange}
              >
                <option value="">Select the options</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Dropdown>
              {errors.waterFacility && <ErrorMessage>{errors.waterFacility}</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="boreWell">Bore-Well Facility:</Label>
              <Dropdown
                name="boreWell"
                value={formData.boreWell}
                onChange={handleInputChange}
              >
                <option value="">Select the options</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Dropdown>
              {errors.boreWell && <ErrorMessage>{errors.boreWell}</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="roadFacility">Road Facility:</Label>
              <Dropdown
                name="roadFacility"
                value={formData.roadFacility}
                onChange={handleInputChange}
              >
                <option value="">Select the options</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Dropdown>
              {errors.roadFacility && <ErrorMessage>{errors.roadFacility}</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="facingdirection">Facing Direction</Label>
              <Input
                type="text"
                name="facingdirection"
                value={formData.facingdirection || ''} // Ensure it does not break if formData.amenities is undefined
                onChange={handleInputChange}
              />
              {errors.amenities && <ErrorMessage>{errors.facingdirection}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="amenities">Amenities</Label>
              <Input
                type="text"
                name="amenities"
                value={formData.amenities || ''} // Ensure it does not break if formData.amenities is undefined
                onChange={handleInputChange}
              />
              {errors.amenities && <ErrorMessage>{errors.amenities}</ErrorMessage>}
            </FormGroup>

            {/* <FormGroup>
            <Label htmlFor="buildUpArea">Build Up Area</Label>
            <Input
              type="text"
              name="buildUpArea"
              value={formData.buildUpArea}
              onChange={handleInputChange}
            />
            {errors.buildUpArea && <ErrorMessage>{errors.buildUpArea}</ErrorMessage>}
          </FormGroup> */}

            <FormGroup>
              <Label htmlFor="ageOfProperty">Age of Property</Label>
              <Input
                type="text"
                name="ageOfProperty"
                value={formData.ageOfProperty}
                onChange={handleInputChange}
              />
              {errors.ageOfProperty && <ErrorMessage>{errors.ageOfProperty}</ErrorMessage>}
            </FormGroup>

            {/* Conditionally render fields */}
            {["House", "Farm House", "Apartment", "Store"].includes(formData.propertyName) && (
              <>
                <FormGroup>
                  <Label htmlFor="numberOfBathrooms">Number of Bathrooms:</Label>
                  <Input
                    type="number"
                    name="numberOfBathrooms"
                    value={formData.numberOfBathrooms}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="numberOfRooms">Number of Rooms:</Label>
                  <Dropdown
                    name="numberOfRooms"
                    value={formData.numberOfRooms}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="1 BHK">1 BHK</option>
                    <option value="2 BHK">2 BHK</option>
                    <option value="3+ BHK">3+ BHK</option>
                    <option value="Single Room">Single Room</option>
                  </Dropdown>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="parking">Parking:</Label>
                  <Dropdown
                    name="parking"
                    value={formData.parking}
                    onChange={handleInputChange}
                  >
                    <option value="">Select the options</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Dropdown>
                </FormGroup>


                <FormGroup>
                  <Label htmlFor="lift">Lift:</Label>
                  <Dropdown
                    name="lift"
                    value={formData.lift}
                    onChange={handleInputChange}
                  >
                    <option value="">Select the options</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Dropdown>

                </FormGroup>
              </>
            )}

            <FullWidthFormGroup columns={columns}>
              <Label htmlFor="contactOwnerName">Contact Owner Name <span style={{ color: "red" }}>*</span></Label>
              <Input
                type="text"
                name="contactOwnerName"
                value={formData.contactOwnerName}
                onChange={handleInputChange}
              />
              {errors.contactOwnerName && <ErrorMessage>{errors.contactOwnerName}</ErrorMessage>}
            </FullWidthFormGroup>

            <FullWidthFormGroup columns={columns}>
              <Label htmlFor="contactOwnerPhone">Contact Owner Phone  <span style={{ color: "red" }}>*</span></Label>
              <Input
                type="number"
                name="contactOwnerPhone"
                value={formData.contactOwnerPhone}
                onChange={handleInputChange}
                pattern="\d{10}"
              />
              {errors.contactOwnerPhone && <ErrorMessage>{errors.contactOwnerPhone}</ErrorMessage>}
            </FullWidthFormGroup>

            <FullWidthFormGroup columns={columns}>
              <Label htmlFor="propertyAddress">Property Address <span style={{ color: "red" }}>*</span></Label>
              <Input
                type="text"
                name="propertyAddress"
                value={formData.propertyAddress}
                onChange={handleInputChange}
              />
              {errors.propertyAddress && <ErrorMessage>{errors.propertyAddress}</ErrorMessage>}
            </FullWidthFormGroup>

            <FormGroup>
              <Label htmlFor="city">City <span style={{ color: "red" }}>*</span></Label>
              <Dropdown
                name="city"
                value={formData.city || ""}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Ariyalur">Ariyalur</option>
                <option value="Chengalpattu">Chengalpattu</option>
                <option value="Chennai">Chennai</option>
                <option value="Coimbatore">Coimbatore</option>
                <option value="Cuddalore">Cuddalore</option>
                <option value="Dharmapuri">Dharmapuri</option>
                <option value="Dindigul">Dindigul</option>
                <option value="Erode">Erode</option>
                <option value="Kallakurichi">Kallakurichi</option>
                <option value="Kancheepuram">Kancheepuram</option>
                <option value="Karur">Karur</option>
                <option value="Krishnagiri">Krishnagiri</option>
                <option value="Madurai">Madurai</option>
                <option value="Mayiladuthurai">Mayiladuthurai</option>
                <option value="Nagapattinam">Nagapattinam</option>
                <option value="Nagercoil">Nagercoil (Kanniyakumari)</option>
                <option value="Namakkal">Namakkal</option>
                <option value="Perambalur">Perambalur</option>
                <option value="Pudukottai">Pudukottai</option>
                <option value="Ramanathapuram">Ramanathapuram</option>
                <option value="Ranipet">Ranipet</option>
                <option value="Salem">Salem</option>
                <option value="Sivagangai">Sivagangai</option>
                <option value="Tenkasi">Tenkasi</option>
                <option value="Thanjavur">Thanjavur</option>
                <option value="Theni">Theni</option>
                <option value="Thiruvallur">Thiruvallur</option>
                <option value="Thiruvarur">Thiruvarur</option>
                <option value="Thoothukudi">Thoothukudi</option>
                <option value="Trichirappalli">Trichirappalli (Trichy)</option>
                <option value="Thirunelveli">Thirunelveli</option>
                <option value="Tirupathur">Tirupathur</option>
                <option value="Tiruppur">Tiruppur</option>
                <option value="Tiruvannamalai">Tiruvannamalai</option>
                <option value="The Nilgiris">The Nilgiris</option>
                <option value="Vellore">Vellore</option>
                <option value="Viluppuram">Viluppuram</option>
                <option value="Virudhunagar">Virudhunagar</option>
              </Dropdown>
              {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="zipCode">Zip Code <span style={{ color: "red" }}>*</span></Label>
              <Input
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleInputChange}
              />
              {errors.zipCode && <ErrorMessage>{errors.zipCode}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="state">State <span style={{ color: "red" }}>*</span></Label>
              <Input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
              {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="createdBy">Created By</Label>
              <Input
                type="text"
                name="createdBy"
                value={formData.createdBy}
                onChange={handleInputChange}
                disabled
              />
            </FormGroup>

            <SubmitButton type="submit" columns={columns}>
              Submit
            </SubmitButton>
          </FormGrid>
        </form>

        <SuccessMessage visible={successMessageVisible}>
          Form Submitted Successfully..!
          And Waiting for Approval.
        </SuccessMessage>
      </FormContainer>

    </PageContainer>
  );
};

export default Sell;
