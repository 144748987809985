import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import useMediaQuery from "../useMediaQuery"; // Import your custom hook
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../AuthProvider"; // Import the authentication context
import Popup from "./PopupMessage";
import Search from '../Components/Search/Search';
// import '@fortawesome/fontawesome-free/css/all.min.css';
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #2e3a59, #3b5066, #434f81, #7c5db2); /* Dark gradient with blue and purple tones */
  padding: 15px 30px;
  box-sizing: border-box;
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick it to the top */
  z-index: 100; /* Ensure it stays above other elements */

  ${({ isMobile }) =>
    isMobile &&
    `
    padding: 10px 15px;
    flex-direction: row;
  `}
`;


const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 100px;
    height: 100px; /* Make sure height matches width for a perfect circle */
    margin-right: 20px;
    border-radius: 50%; /* This makes the image round */
    object-fit: cover; 
    /* Ensures the image covers the area and maintains aspect ratio */
  }
`;
const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  position:relative;
  left:-35px;
  // right:50px;
  ${({ isMobile, menuOpen }) =>
    isMobile &&
    `
    display: ${menuOpen ? "flex" : "none"};
    width: 100%;
    max-width: 100vw; /* Ensure it doesn't exceed viewport width */
    position: absolute;
    top: 120px;
    left: 0;
    background: linear-gradient(135deg, #2e3a59, #3b5066, #434f81, #7c5db2); /* Dark gradient with blue and purple tones */
    padding: 20px;
    z-index: 1000;
    box-sizing: border-box; /* Ensure padding is included in width */
    border: 2px solid rgba(255, 255, 255, 0.3); /* Mirror-like border */
    border-radius: 10px; /* Adds a rounded border */
    overflow-x: hidden; /* Prevent horizontal overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Softer shadow */
    color: white;
  `}
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  a {
    color: ${({ theme }) => theme.colors.light};
    text-decoration: none;
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 5px;
    position: relative;
    margin-bottom: 10px;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
      1px 1px 0 black; /* Black outline effect */
    &.active {
      font-weight: bold;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: ${({ isMobile, theme }) =>
    isMobile
      ? theme.colors.medium
      : theme.colors
        .light}; /* Active background color changes based on screen size */
        bottom: -2px;
        left: 0;
      }
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    `
   
    flex-direction: column;
    gap: 20px;
   
  `}
`;
const DesktopSearchContainer = styled.div`
 position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 20px;
  padding: 5px 15px;
  width: 300px;
  input {
    border: none;
    background: none;
    padding: 5px;
    font-size: 16px;
    outline: none;
    flex-grow: 1;
    color: ${({ theme }) => theme.colors.dark};
  }
  button {
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.dark};
  }
`;
const MobileSearchContainer = styled.div`
  position: relative;
  display: ${({ searchOpen }) => (searchOpen ? "flex" : "none")};
  align-items: center;
  background-color: rgba(42, 46, 39, 0.2); /* Semi-transparent dark background for glass effect */
  backdrop-filter: blur(10px); /* Creates the glassy blur effect */
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3); /* Semi-transparent border for mirror-like effect */
  padding: 10px;
  position: absolute;
  top: 80px;
  left: 5px;
  z-index: 1001;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  
  width: calc(100% - 20px); /* Default for most screens */
 
  
  @media (max-width: 768px) {
    width: calc(100% - 40px); /* Slightly reduce width on tablets */
  }
  
  @media (max-width: 480px) {
    width: calc(100% - 20px); /* For small screens (mobile phones) */
    left: 10px; /* Adjust left position on mobile */
    padding: 8px; /* Adjust padding for smaller screens */
    margin-top: 45px;

    /* Apply the gradient background for mobile */
    background: linear-gradient(135deg, #2e3a59, #3b5066, #434f81, #7c5db2); /* Dark gradient with blue and purple tones */
    backdrop-filter: none; /* Remove the glassy blur effect on mobile */
    
  }

  input {
    border: none;
    background: none;
    padding: 5px;
    font-size: 16px;
    outline: none;
    flex-grow: 1;
    color: ${({ theme }) => theme.colors.light};
  }

  button {
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.light};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.light};
  cursor: pointer;
  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 20px;
  `}
`;
const MobileSearchIcon = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.light};
  ${({ isMobile }) =>
    isMobile &&
    `
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.light};
  `}
`;
const UserOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    color: ${({ theme }) => theme.colors.light};
    cursor: pointer;
    font-size: 18px;
    i {
      font-size: 22px;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.medium};
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    `
    gap: 10px;
  `}
`;
const HighlightedText = styled.span`
  background-color: yellow;
`;

const SearchDropdown = styled.div`
  position: absolute;
  top: 110%; /* Positions it right below the search bar */
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius:15px;
  z-index: 999; /* Ensures it appears above other content */
  max-height: 200px; /* Optional: Limit dropdown height */
  overflow-y: auto; /* Allows scrolling if there are many suggestions */
  
  .dropdown-header {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    background-color: #f9f9f9;
  }

  .suggestion-item {
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  }

  .no-suggestions {
    padding: 10px;
    color: #888;
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.light};
  ${({ isMobile }) =>
    isMobile &&
    `
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    
    color: ${({ theme }) => theme.colors.light};
  `}
`;
const Header = () => {
  const { isAuthenticated, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Sell");
  const [popupVisible, setPopupVisible] = useState(false);
  // Search related state and functions
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const searchRef = useRef(null); // for clicking outside
  // const [activeLink, setActiveLink] = useState('');
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const highlightQuery = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <HighlightedText key={index}>{part}</HighlightedText>
      ) : (
        part
      )
    );
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (searchOpen) {
      setSearchOpen(false);
    }
  };
  // Fetch suggestions based on the user's input
  const fetchSuggestions = async () => {
    if (!query.trim()) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/searchsuggestion`, {
        params: { query },
      });
      let fetchedSuggestions = response.data;

      fetchedSuggestions = fetchedSuggestions.sort((a, b) => {
        const queryLower = query.toLowerCase();
        const aPropertyStartsWith = a.propertyName.toLowerCase().startsWith(queryLower);
        const bPropertyStartsWith = b.propertyName.toLowerCase().startsWith(queryLower);
        const aCityStartsWith = a.city?.toLowerCase().startsWith(queryLower) || false;
        const bCityStartsWith = b.city?.toLowerCase().startsWith(queryLower) || false;
        if (aPropertyStartsWith || aCityStartsWith) return -1;
        if (bPropertyStartsWith || bCityStartsWith) return 1;
        return 0;
      });
      setSuggestions(fetchedSuggestions);
      setIsDropdownOpen(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  // Trigger fetching suggestions when the query changes
  useEffect(() => {
    if (query.trim()) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
      setIsDropdownOpen(false);
    }
  }, [query]);

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle query change
  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  // Handle suggestion click and navigation
// Handle suggestion click and navigation
const handleSuggestionClick = (suggestion) => {
  const path = `/search/${encodeURIComponent(suggestion.propertyName || '')}/${encodeURIComponent(suggestion.city || '')}`;
  navigate(path);
  setQuery('');
  setSuggestions([]);
  setIsDropdownOpen(false);
};

  // Handle manual search (when pressing the search button)
  const handleSearch = () => {
    const path = `/propertylistings/${encodeURIComponent(query)}`;
    navigate(path);
    setQuery('');
    setSuggestions([]);
    setIsDropdownOpen(false);
  };
  const handleLogoClick = () => {
    if (window.location.pathname === '/common/Sell') {
      window.scrollTo(0, 0);
    } else {
      setActiveLink('Sell');
      navigate('/common/Sell');
      if (isMobile) {
        setMenuOpen(false); // Close menu on mobile after navigating
      }

    }
  };
  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
    if (menuOpen) {
      setMenuOpen(false);
    }
  };
  const closePopup = () => {
    setPopupVisible(false); // Close the popup
  };
  const handleLogin = () => {
    navigate('/signin');
    setPopupVisible(false);
  }
  const handleSellClick = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      setPopupVisible(true); // Show the popup instead of an alert
      return;
    }
    handleLinkClick('Sell form');
  };
  // Function to handle link clicks and navigation
  const handleLinkClick = (link) => {
    setActiveLink(link)
    if (link === "Sell form") {
      navigate("/sell"); // Navigate to the Sell page
    } else if (link === "Contact") {
      navigate("/contact"); // Navigate to the Contact page
    }
    // else if (link === "Newest Listings") {
    //   navigate(`/newestlisting/${link}`); // Navigate to the Newest_Listing page
    // }
    else if (link === "Featured Collections") {
      navigate(`/featuredcollections/${link}`); // Navigate to the Newest_Listing page
    } else {
      navigate(`/common/${link}`); // For other links
    }
    if (isMobile) {
      setMenuOpen(false); // Close menu on mobile after navigation
    }
  };
  const handleLoginClick = () => {
    navigate("/signin");
  };
  // const handleLogoutClick = () => {
  //   // Call the logout function from AuthProvider
  //   logout();
  //   // Optionally navigate the user to the signin page or homepage
  //   navigate('/signin'); // Or navigate('/') for the homepage
  // };
  return (
    <HeaderContainer isMobile={isMobile}>
      {isMobile && (
        <HamburgerMenu onClick={toggleMenu} isMobile={isMobile}>
          {menuOpen ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </HamburgerMenu>
      )}
      <Logo>
        <img
          onClick={handleLogoClick}
          src="https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_21.png?v=1726134768"
          alt="Logo"
        />
      </Logo>
      <CenterContainer isMobile={isMobile} menuOpen={menuOpen}>
        <NavLinks isMobile={isMobile}>
          <a
            className={activeLink === "Sell" ? "active" : ""}
            onClick={() => handleLinkClick("Sell")}
          >
            Buy
          </a>
          <a
            className={activeLink === "Sell form" ? "active" : ""}
            onClick={handleSellClick}
          >
            Sell
          </a>
          <a
            className={activeLink === "Rent" ? "active" : ""}
            onClick={() => handleLinkClick("Rent")}
          >
            Rent
          </a>
          <a
            className={activeLink === "Lease" ? "active" : ""}
            onClick={() => handleLinkClick("Lease")}
          >
            Lease
          </a>
          <a
            className={activeLink === "Contact" ? "active" : ""}
            onClick={() => handleLinkClick("Contact")}
          >
            Contact Us
          </a>
        </NavLinks>

        {!isMobile && (
          <DesktopSearchContainer ref={searchRef}>
            <input
              type="text"
              placeholder="Enter the address, city"
              value={query}
              onChange={handleInputChange}
              onClick={() => setIsDropdownOpen(suggestions.length > 0)}
            />
            <button >
              <i className="fas fa-search"></i>
            </button>
            {isDropdownOpen && suggestions.length > 0 && (
              <SearchDropdown isOpen={isDropdownOpen}>
                <div className="dropdown-header">
                  <button onClick={() => { setQuery(''); setIsDropdownOpen(false); }}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                {suggestions.length > 0 ? (
                  suggestions.map((item, index) => (
                    <div key={index} className="suggestion-item" onClick={() => handleSuggestionClick(item)}>
                      <span>{highlightQuery(item.propertyName, query)}</span>
                      {item.city && <span>, {highlightQuery(item.city, query)}</span>}
                    </div>
                  ))
                ) : (
                  <p>No suggestions available</p>
                )}
              </SearchDropdown>
            )}
          </DesktopSearchContainer>
        )}
      </CenterContainer>
      {isMobile && (
        <>
          {!searchOpen && (
            <MobileSearchIcon onClick={toggleSearch} isMobile={isMobile}>
              <i className="fas fa-search"></i>
            </MobileSearchIcon>
          )}
          <MobileSearchContainer searchOpen={searchOpen}>
            <CloseButton onClick={toggleSearch} isMobile={isMobile}>
              <i className="fas fa-times"></i>
            </CloseButton>
            <input
              type="text"
              placeholder="Enter the address, city"
              value={query}
              onChange={handleInputChange}
            />
            <button onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
            {isDropdownOpen && suggestions.length > 0 && (
              <SearchDropdown isOpen={isDropdownOpen}>
                {suggestions.length > 0 ? (
                  suggestions.map((item, index) => (
                    <div key={index} className="suggestion-item" onClick={() => handleSuggestionClick(item)}>
                      <span>{highlightQuery(item.propertyName, query)}</span>
                      {item.city && <span>, {highlightQuery(item.city, query)}</span>}
                    </div>
                  ))
                ) : (
                  <p>No suggestions available</p>
                )}
              </SearchDropdown>
            )}
          </MobileSearchContainer>

        </>
      )}
      <UserOptions isMobile={isMobile}>
        {!isAuthenticated ? (
          <span onClick={handleLoginClick}>
            Login <i className="fas fa-sign-in-alt"></i>
          </span>
        ) : (
          <>
            {/* <span onClick={() => navigate("/wishlist")}>
              <i className="fas fa-heart"></i>
            </span> */}
            <span onClick={() => navigate("/profile")}>
              <i className="fas fa-user"></i>
            </span>
            {/* <span onClick={handleLogoutClick}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </span> */}
          </>
        )}
      </UserOptions>
      {popupVisible && <Popup message="Please login before accessing the Sell page." onClose={closePopup} onLogin={handleLogin} />}
    </HeaderContainer>

  );
};
export default Header;
