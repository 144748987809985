import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
const Plans = () => {
  const [planName, setPlanName] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [timeUnit, setTimeUnit] = useState('month'); // Add state for time unit
  const [message, setMessage] = useState('');
  const [plans, setPlans] = useState([]);
  const [editPlanId, setEditPlanId] = useState(null);
  useEffect(() => {
    fetchPlans();
  }, []);
  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/plans`);
      setPlans(response.data);
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPlan = {
      planName,
      price: parseInt(price),
      duration: parseInt(duration),  // Ensure duration is a number
      timeUnit,  // Include timeUnit in the plan data
    };
    try {
      if (editPlanId) {
        await axios.put(`${process.env.REACT_APP_API_URL}/plans/${editPlanId}`, newPlan);
        setMessage('Plan updated successfully!');
        setEditPlanId(null);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/plans`, newPlan);
        setMessage('Plan added successfully!');
      }
      setPlanName('');
      setPrice('');
      setDuration('');
      setTimeUnit('hour');  // Reset to default value
      fetchPlans();
    } catch (error) {
      console.error('Error adding/updating plan:', error);
      setMessage('Error adding/updating plan. Please try again.');
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/plans/${id}`);
      setMessage('Plan deleted successfully!');
      fetchPlans();
    } catch (error) {
      console.error('Error deleting plan:', error);
      setMessage('Error deleting plan. Please try again.');
    }
  };
  const handleEdit = (plan) => {
    setPlanName(plan.planName);
    setPrice(plan.price);
    setDuration(plan.duration);
    setTimeUnit(plan.timeUnit);  // Set the selected time unit during edit
    setEditPlanId(plan._id);
  };
  return (
    <MainContainer>
      <FormContainer>
        <h2>{editPlanId ? 'Edit Plan' : 'Add New Plan'}</h2>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <label>Plan Name:</label>
            <input
              type="text"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              required
            />
          </InputWrapper>     
          <InputWrapper>
            <label>Price:</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </InputWrapper>
          <InputWrapper>
            <label>Duration:</label>
            <input
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}   
              min="1"  // Ensure the duration is positive
              required
            />
          </InputWrapper>
          <InputWrapper>
            <label>Time Unit:</label>
            <select value={timeUnit} onChange={(e) => setTimeUnit(e.target.value)} required>
              {/* <option value="minute">Minute</option> */}
              {/* <option value="hour">Hour</option> */}
              {/* <option value="day">Day</option> */}
              {/* <option value="week">Week</option> */}
              <option value="month">Month</option>
              {/* <option value="year">Year</option> */}
            </select>
          </InputWrapper>
          <Button type="submit">{editPlanId ? 'Update Plan' : 'Add Plan'}</Button>
        </form>
        {message && <Message>{message}</Message>}
      </FormContainer>
      <PlanListContainer>
        <h2>Current Plans</h2>
        {plans.length > 0 ? (
          <PlanList>
            {plans.map((plan) => (
              <PlanItem key={plan._id}>
                <PlanDetails>
                  <span>{plan.planName}</span>
                  <span>₹{plan.price}</span>
                  <span>{plan.duration} {plan.timeUnit}</span>  {/* Display timeUnit */}
                </PlanDetails>
                <ButtonGroup>
                  <Button onClick={() => handleEdit(plan)}>Edit</Button>
                  <ButtonDelete onClick={() => handleDelete(plan._id)}>Delete</ButtonDelete>
                </ButtonGroup>
              </PlanItem>
            ))}
          </PlanList>
        ) : (
          <p>No plans available</p>
        )}
      </PlanListContainer>
    </MainContainer>
  );
};
// Styled components with responsiveness and modern design
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px; /* Increased width */
  margin: auto;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 15px;
  }
`;
const FormContainer = styled.div`
  background: #F9F9F9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 600px; /* Increased width */
  max-width: 90%;
  margin-bottom: 20px;
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const InputWrapper = styled.div`
  margin-bottom: 15px;
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
`;
const Button = styled.button`
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #45A049;
  }
`;
const ButtonDelete = styled(Button)`
  background-color: #F44336;
  &:hover {
    background-color: #E41D2D;
  }
`;
const Message = styled.div`
  margin-top: 20px;
  font-weight: bold;
  color: green;
`;
const PlanListContainer = styled.div`
  width: 90%; /* Increased width */
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`;
const PlanList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const PlanItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;
const PlanDetails = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  span {
    font-weight: bold;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;
export default Plans;














